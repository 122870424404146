import { useState } from 'react';
import Dropdown from 'UILib/Dropdown/Dropdown';
import TextArea from 'UILib/TextArea/TextArea';
import Button from 'UILib/Button/Button';
import Link from 'UILib/Link/Link';
import BroadcastEditor from './BroadcastEditor/BroadcastEditor';
import { IToneData } from '../types';
import { ReactComponent as Back } from 'Assets/arrow-left-back.svg';
import { ReactComponent as MailIcon } from 'Assets/icons/mail.svg';
import { ReactComponent as ArrowRightIcon } from 'Assets/icons/arrowRight.svg';

import styles from './BroadcastGenerator.module.scss';

const tones = [
  { label: 'Professional', value: 'professional' },
  { label: 'Semi-Professional', value: 'semi-professional' },
  { label: 'Casual', value: 'casual' },
  { label: 'Friendly', value: 'friendly' },
  { label: 'Inspirational', value: 'inspirational' },
  { label: 'Humorous', value: 'humorous' },
  { label: 'Urgent', value: 'urgent' },
  { label: 'Confident', value: 'confident' },
  { label: 'Empathetic', value: 'emphatic' },
  { label: 'Playful', value: 'playful' },
  { label: 'Authoritative', value: 'Authoritative' },
  { label: 'Custom', value: 'custom' },
];

const BroadcastGenerator = () => {
  const [data, setData] = useState<IToneData>({});
  const [showEditor, setShowEditor] = useState(false);

  const handleEditorRedirectBack = () => {
    setShowEditor(!showEditor);
  };

  if (showEditor) {
    return <BroadcastEditor handleGoBack={handleEditorRedirectBack} />;
  }

  return (
    <div className={styles.container}>
      <Link
        to="/console/outreach/broadcasts"
        className={styles.backLink}
        prefixIcon={<Back />}
      >
        Dashboard
      </Link>
      <div className={styles.header}>
        <MailIcon />
        <div className={styles.title}>Generate Email with AI</div>
        <div className={styles.subtitle}>
          Describe and generate or skip this step and fill in your email from
          scratch
        </div>
      </div>
      <div className={styles.form}>
        <div>
          Tone:
          <Dropdown
            label="Select a Tone"
            value={data.tone}
            type="stroke"
            options={tones}
            onChange={(newValue) => setData({ ...data, tone: newValue })}
          />
        </div>
        <div>
          Description:
          <TextArea
            height={230}
            value={data.description}
            placeholder="Describe here the offer you are promoting"
            onChange={(newValue) => setData({ ...data, description: newValue })}
          />
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Button appearance="stroke" onClick={() => setShowEditor(true)}>
          Skip this Step
        </Button>
        <Button
          appearance="solid"
          onClick={() => setShowEditor(true)}
          postfixIcon={<ArrowRightIcon />}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default BroadcastGenerator;
