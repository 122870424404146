import React, { useState } from 'react';
import { connect } from 'react-redux';
import { updateBookleTemplateBlocks } from 'store/books/booksActions';
import { RootState } from 'store/rootReducer';
import { BookleTemplateBlock } from 'types';
import { findItemById } from '../utils';

import styles from './ImageBlock.module.scss';

interface IProps {
  item: BookleTemplateBlock;
  updateBlocks: (payload: BookleTemplateBlock[]) => void;
  templateBlocks: BookleTemplateBlock[];
}

const ImageBlock = ({
  item,
  updateBlocks,
  templateBlocks,
}: IProps): JSX.Element => {
  const [image, setImage] = useState<string | ArrayBuffer | null>(
    item?.image || ''
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      const reader = new FileReader();
      const newArray = [...templateBlocks];
      reader.onloadend = () => {
        const imageItem: BookleTemplateBlock = findItemById(
          newArray,
          item.id as string
        );
        if (imageItem) {
          imageItem.image = reader.result as string;
          updateBlocks(newArray);
        }
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className={styles.container}>
      <input
        type="file"
        accept={'image/*'}
        onChange={handleChange}
        id={`image-${item.id}`}
      />
      <label htmlFor={`image-${item.id}`} className={styles.imageContent}>
        {image ? (
          <div
            style={{ backgroundImage: `url(${image as string})` }}
            className={styles.image}
          />
        ) : (
          <div>Upload Image</div>
        )}
      </label>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  templateBlocks: state.books.bookleTemplateBlocks,
});

const mapDispatchToProps = {
  updateBlocks: (payload: BookleTemplateBlock[]) =>
    updateBookleTemplateBlocks(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageBlock);
