import { BookleTemplateBlock } from 'types';
import { MenuItems } from './Sidebar/Sidebar';

export const addItemToContainer = (
  item: BookleTemplateBlock,
  blocks: Array<BookleTemplateBlock>
): BookleTemplateBlock[] => {
  const newArray = [...blocks];

  let newItem = item.id
    ? findItemById(newArray, item.id, true) || item
    : createNewItem(item);
  newArray.push(newItem);
  return newArray;
};

const createNewItem = (item: any) => {
  const newItem = {
    id: generateNewIndex(),
    type: item?.type,
  };

  if (item?.type?.includes('section')) {
    return {
      ...newItem,
      items: Array.from({ length: Number(item?.type.split('_')[1]) }).map(
        () => ({
          id: generateNewIndex(),
          items: [],
        })
      ),
    };
  }

  return newItem;
};

export function findItemById(
  items: any[],
  id: string,
  isRemove?: boolean
): any {
  if (!Array.isArray(items)) {
    return null;
  }

  for (let i = 0; i < items.length; i++) {
    let item = items[i];

    if (item?.id === id) {
      if (isRemove) {
        return items.splice(i, 1)[0];
      }
      return item;
    }

    if (item?.items && item?.items?.length > 0) {
      let found = findItemById(item.items, id, isRemove);
      if (found) {
        return found;
      }
    }
  }
  return null;
}

export function findAndDuplicate(
  array: BookleTemplateBlock[],
  targetId: string
) {
  function duplicateItem(item: BookleTemplateBlock): BookleTemplateBlock {
    const newItem = JSON.parse(JSON.stringify(item));
    const queue: BookleTemplateBlock[] = [newItem];

    while (queue.length > 0) {
      const current = queue.shift();
      if (current) {
        current.id = generateNewIndex();
        if (current.items) {
          queue.push(...current.items);
        }
      }
    }

    return newItem;
  }

  function recursiveFindAndInsert(arr: any[], id: string): boolean {
    for (let i = 0; i < arr?.length; i++) {
      if (arr[i]?.id === id) {
        const newItem = duplicateItem(arr[i]);
        arr?.splice(i + 1, 0, newItem);
        return true;
      } else if (arr[i]?.items && arr[i]?.items.length > 0) {
        if (recursiveFindAndInsert(arr[i]?.items, id)) {
          return true;
        }
      }
    }
    return false;
  }

  recursiveFindAndInsert(array, targetId);
}

export const moveItem = (
  hoveredItem: BookleTemplateBlock,
  draggedItem: BookleTemplateBlock,
  isFirstHalf: boolean,
  items: Array<BookleTemplateBlock>,
  setItems: (items: Array<BookleTemplateBlock>) => void,
  parentId?: string
) => {
  const newArray = [...items];
  let parent: any = parentId ? findItemById(newArray, parentId) : null;
  const hoverItem = findItemById(newArray, hoveredItem.id as string);

  const container = parent?.id ? parent.items : newArray;

  const newIndex = container.indexOf(hoverItem) + (isFirstHalf ? 0 : 1);

  const itemToMove = draggedItem.id
    ? findItemById(newArray, draggedItem.id as string, true) || draggedItem
    : createNewItem(draggedItem);

  if (parent?.id) {
    parent.items.splice(newIndex, 0, itemToMove);
  } else {
    newArray.splice(newIndex, 0, itemToMove);
  }

  setItems(newArray);
};

export default addItemToContainer;

export function generateNewIndex(): string {
  return Math.random().toString(36).substr(2, 9);
}

export function isChildOf(
  items: BookleTemplateBlock[],
  parentId: string,
  childId: string
): boolean {
  const parent = findItemById(items, parentId);

  if (!parent || !parent.items || parent.items.length === 0) {
    return false;
  }

  return recursiveChildSearch(parent.items, childId);
}

function recursiveChildSearch(
  items: BookleTemplateBlock[] | undefined,
  childId: string
): boolean {
  if (!items) return false;
  for (let i = 0; i < items.length; i++) {
    if (items[i].id === childId) {
      return true;
    }

    if (items[i].items && (items?.[i]?.items as any).length > 0) {
      if (recursiveChildSearch(items[i].items, childId)) {
        return true;
      }
    }
  }

  return false;
}

export class Preview2HTML {
  constructBlock(previewBlock: BookleTemplateBlock): string {
    let block = '';
    switch (previewBlock.type) {
      case MenuItems.IMAGE_BLOCK: {
        block = `<div><img style="max-width: 100%;" src="${previewBlock.image}"/></div>`;
        break;
      }
      case MenuItems.TEXT_BLOCK: {
        const textEditorElement = document.getElementById(
          `text_editor_${previewBlock.id}`
        );
        if (textEditorElement) {
          const contentEditableElements = textEditorElement.querySelectorAll(
            '[contentEditable="true"]'
          );
          contentEditableElements.forEach((element) => {
            element.removeAttribute('contentEditable');
          });

          const htmlContent = textEditorElement.innerHTML;
          block = `<div>${htmlContent}</div>`;
        }
        break;
      }
      case MenuItems.ONE_SECTION:
      case MenuItems.TWO_SECTION:
      case MenuItems.THREE_SECTION:
      case MenuItems.FOUR_SECTION: {
        block = `<div style="display: flex;">${previewBlock.items
          ?.map(
            (item: BookleTemplateBlock) =>
              `<div style="flex: 1;">${this.setCorrespondingBlocks(
                item.items || [],
                true
              )}</div>`
          )
          .join('')}</div>`;
      }
    }

    return block;
  }

  setCorrespondingBlocks(
    previewBlocks: BookleTemplateBlock[],
    isSection?: boolean
  ): string {
    let blockContainer = '';
    for (const previewBlock of previewBlocks) {
      blockContainer += this.constructBlock(previewBlock);
    }

    if (isSection) {
      return blockContainer;
    }

    return `
            <!DOCTYPE html>
            <html lang="en">
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>Generated HTML</title>
            </head>
            <body>
                <div>${blockContainer}</div>
            </body>
            </html>
        `;
  }
}
