import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import clsx from 'clsx';
import { RootState } from 'store/rootReducer';
import SettingsModal from './SettingsModal/SettingsModal';
import { MenuItems, Sidebar } from '../Draggable/Sidebar/Sidebar';
import { updateBookleTemplateBlocks } from 'store/books/booksActions';
import { IGenerationBlock } from './GenerationBlock/GenerationBlock';
import { findItemById, Preview2HTML } from '../Draggable/utils';
import { UseOnClickOutside } from 'utils/UseOnClickOutside';
import { BookleTemplateBlock } from 'types';
import { ReactComponent as PlusIcon } from 'Assets/icons/plus.svg';
import { ReactComponent as SettingsIcon } from 'Assets/icons/contextMenu/settingsIcon.svg';
import Button from 'UILib/Button/Button';
import Content from '../Draggable/Content/Content';

import styles from './GenerationPreview.module.scss';

interface IProps {
  generationBlocks: IGenerationBlock[];
  templateBlocks: BookleTemplateBlock[];
  updateBlocks: (payload: BookleTemplateBlock[]) => void;
  isSidebarHidden?: boolean; // this attribute will hide the sidebar toggle
  onSidebarToggle?: (isSidebarOpen: boolean) => void; // even't which should be handled once toggle sidebar
}

const GenerationPreview = (props: IProps) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const settingsModalRef = useRef<HTMLDivElement | null>(null);

  UseOnClickOutside(
    settingsModalRef,
    (event) => isSettingsModalOpen && handleCloseSettings(event as MouseEvent)
  );

  useEffect(() => {
    if (props.isSidebarHidden) {
      setIsSidebarOpen(false);
    }
  }, [props.isSidebarHidden]);

  useEffect(() => {
    props.generationBlocks.map((item: IGenerationBlock) => {
      const existedItem = findItemById(props.templateBlocks, item.node.id);

      if (!existedItem) {
        return props.updateBlocks([
          ...props.templateBlocks,
          {
            id: item.node.id,
            type: item.node.type?.includes('Image')
              ? MenuItems.IMAGE_BLOCK
              : MenuItems.TEXT_BLOCK,
            variable: item.node.variable,
            ...(item.node.type?.includes('Text') && {
              readOnly: true,
              text: [
                {
                  type: 'paragraph',
                  children: [{ text: item.node?.prompt || '' }],
                },
              ],
            }),
          },
        ]);
      } else {
        const newArray = [...props.templateBlocks];
        existedItem.variable = item.node.variable;
        if (existedItem.type === MenuItems.TEXT_BLOCK) {
          existedItem.text = [
            {
              type: 'paragraph',
              children: [{ text: item.node?.prompt || '' }],
            },
          ];
        }

        props.updateBlocks(newArray);
      }
    });
  }, [props.generationBlocks]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    props.onSidebarToggle && props.onSidebarToggle(!isSidebarOpen);
  };

  const generatePreviewHtml = () => {
    const convertor = new Preview2HTML();
    const blocksHtmlContent = convertor.setCorrespondingBlocks(
      props.templateBlocks
    );
    console.log(blocksHtmlContent);
  };

  const handleCloseSettings = (event: MouseEvent) => {
    if (
      settingsModalRef.current &&
      !settingsModalRef.current.contains(event.target as Node)
    ) {
      setIsSettingsModalOpen(false);
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Button
        className={clsx(styles.addNewFormButton, styles.openSidebarButton, {
          [styles.opened]: isSidebarOpen,
          [styles.hiddenSidebar]: props.isSidebarHidden,
        })}
        prefixIcon={<PlusIcon />}
        appearance="solid"
        width={40}
        height={40}
        onClick={toggleSidebar}
      />
      {isSettingsModalOpen ? (
        <div ref={settingsModalRef}>
          <SettingsModal />
        </div>
      ) : (
        <Button
          className={`${styles.addNewFormButton} ${styles.settingsButton}`}
          prefixIcon={<SettingsIcon className={styles.settingsIcon} />}
          appearance="solid"
          width={40}
          height={40}
          onClick={() => {
            setIsSettingsModalOpen(!isSettingsModalOpen);
          }}
        />
      )}
      <div className={styles.container}>
        <div className={clsx(styles.sidebar, !isSidebarOpen && styles.closed)}>
          <Sidebar />
        </div>
        <Content />
      </div>
    </DndProvider>
  );
};

const mapStateToProps = (state: RootState) => ({
  templateBlocks: state.books.bookleTemplateBlocks,
});

const mapDispatchToProps = {
  updateBlocks: (payload: BookleTemplateBlock[]) =>
    updateBookleTemplateBlocks(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(GenerationPreview);
