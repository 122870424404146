import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useDrag, useDrop } from 'react-dnd';
import {
  updateBookleTemplateBlocks,
  updateBookleTemplateHoveredBlock,
} from 'store/books/booksActions';
import { RootState } from 'store/rootReducer';
import { MenuItems } from '../Sidebar/Sidebar';
import { BookleTemplateBlock } from 'types';
import { moveItem } from '../utils';
import DropPlace from '../DropPlace/DropPlace';
import SectionBlocks from '../SectionBlock/SectionBlocks';
import ImageBlock from '../ImageBlock/ImageBlock';
import TextBlock from '../TextBlock/TextBlock';

import styles from './DraggableContent.module.scss';

const DraggableItem = ({ item }: { item: BookleTemplateBlock }) => {
  switch (item?.type) {
    case MenuItems.ONE_SECTION:
    case MenuItems.TWO_SECTION:
    case MenuItems.THREE_SECTION:
    case MenuItems.FOUR_SECTION:
      return <SectionBlocks item={item} />;
    case MenuItems.TEXT_BLOCK:
      return <TextBlock item={item} />;
    case MenuItems.IMAGE_BLOCK:
      return <ImageBlock item={item} />;
    default:
      return <></>;
  }
};

interface IProps {
  item: BookleTemplateBlock;
  parentId?: string;
  updateHoveredItem: (payload: BookleTemplateBlock | undefined) => void;
  templateBlocks: BookleTemplateBlock[];
  hoveredItem: BookleTemplateBlock | undefined;
  updateBlocks: (payload: BookleTemplateBlock[]) => void;
}

const DraggableContent = ({
  item,
  parentId,
  updateHoveredItem,
  templateBlocks,
  hoveredItem,
  updateBlocks,
}: IProps): JSX.Element => {
  const [isHovered, setIsHovered] = useState(false);
  const dropRef = useRef<HTMLDivElement | null>(null);
  const [isFirstHalf, setIsFirstHalf] = useState<boolean>(false);

  useEffect(() => {
    setIsHovered(hoveredItem === item);
  }, [item, hoveredItem]);

  const handleActive = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    event.stopPropagation();
  };

  const isInHalfPart = (monitor: any) => {
    const dropTarget = monitor.getClientOffset();
    const hoverBoundingRect = dropRef.current?.getBoundingClientRect();
    if (hoverBoundingRect && hoverBoundingRect.height < 100) {
      return false;
    }

    if (hoverBoundingRect && dropTarget) {
      const hoverMiddleY = hoverBoundingRect.top + hoverBoundingRect.height / 2;
      return dropTarget.y < hoverMiddleY;
    } else {
      return false;
    }
  };

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: 'MENU_ITEM',
      hover: (_, monitor) => {
        if (monitor.isOver({ shallow: true })) {
          updateHoveredItem(item);
          setIsFirstHalf(isInHalfPart(monitor));
        }
      },
      drop(draggedItem: any, monitor) {
        if (monitor.isOver({ shallow: true }) && hoveredItem) {
          moveItem(
            item as BookleTemplateBlock,
            draggedItem,
            isFirstHalf,
            templateBlocks,
            updateBlocks,
            parentId
          );
        }
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver({ shallow: true }),
      }),
    }),
    [item, parentId, isFirstHalf, templateBlocks]
  );

  const [, drag] = useDrag(() => ({
    type: 'MENU_ITEM',
    item,
  }));

  useEffect(() => {
    if (isOver) {
      updateHoveredItem(item);
    } else {
      updateHoveredItem(undefined);
    }
  }, [isOver, item]);

  return (
    <>
      <div
        ref={(node) => {
          drag(node);
          drop(node);
          dropRef.current = node;
        }}
        className={styles.container}
        onClick={handleActive}
      >
        {isHovered && isFirstHalf && <DropPlace />}
        <DraggableItem item={item} />
        {item.variable && (
          <span className={styles.variable}>#{item.variable}</span>
        )}
        {isHovered && !isFirstHalf && <DropPlace />}
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  templateBlocks: state.books.bookleTemplateBlocks,
  hoveredItem: state.books.bookleTemplateHoveredBlock,
});

const mapDispatchToProps = {
  updateBlocks: (payload: BookleTemplateBlock[]) =>
    updateBookleTemplateBlocks(payload),
  updateHoveredItem: (payload: BookleTemplateBlock | undefined) =>
    updateBookleTemplateHoveredBlock(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(DraggableContent);
