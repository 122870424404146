import { useRef } from 'react';
import clsx from 'clsx';
import { ReactComponent as Arrow } from 'Assets/icons/arrow.svg';
import { ReactComponent as ImageIcon } from 'Assets/icons/rounded-image.svg';
import { UseOnClickOutside } from 'utils/UseOnClickOutside';
import { PageContainerState } from 'types';
import Input from 'UILib/Input/Input';

import styles from './ChangePaddingModal.module.scss';

interface IProps {
  open: boolean;
  onClose: () => void;
  onChange: (type: string, value: string) => void;
  pageSizes: PageContainerState;
}

const ControlInput = ({
  type,
  value,
  handleChange,
}: {
  type: 'vertical' | 'horizontal';
  value: string;
  handleChange: (value: string) => void;
}) => {
  const isVertical = type === 'vertical';

  const handleIncrease = () => {
    Number(value) < 100 && handleChange((Number(value) + 1).toString());
  };

  const handleDecrease = () => {
    Number(value) > 0 && handleChange((Number(value) - 1).toString());
  };

  return (
    <div
      className={clsx(
        styles.marginInput,
        isVertical ? styles.vertical : styles.horizontal
      )}
    >
      <Arrow
        className={clsx(
          styles.arrow,
          isVertical ? styles.arrowTop : styles.arrowLeft
        )}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          isVertical ? handleIncrease() : handleDecrease();
        }}
      />
      <Input
        type="number"
        min={0}
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        hideArrows
        className={styles.customInput}
      />
      <Arrow
        className={clsx(
          styles.arrow,
          isVertical ? styles.arrowBottom : styles.arrowRight
        )}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          isVertical ? handleDecrease() : handleIncrease();
        }}
      />
    </div>
  );
};

const ChangePaddingModal = ({ open, onClose, onChange, pageSizes }: IProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  UseOnClickOutside(modalRef, () => open && onClose());

  if (!open) return null;

  return (
    <div className={styles.container} ref={modalRef}>
      <div className={styles.title}>Page Container</div>
      <div className={styles.paddingContainer}>
        <div className={styles.paddingTitle}>Inner Padding (мм)</div>
        <div className={styles.paddingInputContainer}>
          {(['pl', 'pt', 'pr'] as Array<keyof PageContainerState>).map(
            (side) => (
              <Input
                key={side}
                type="number"
                min={0}
                className={styles.paddingInput}
                value={pageSizes[side] as any}
                onChange={(e) => onChange(side, e.target.value)}
                hideArrows
              />
            )
          )}
        </div>
      </div>
      <div className={styles.marginContainer}>
        <ControlInput
          type="horizontal"
          value={pageSizes.mt}
          handleChange={(value) => onChange('mt', value)}
        />
        <div className={styles.horizontalMargin}>
          <ControlInput
            type="vertical"
            value={pageSizes.ml}
            handleChange={(value) => onChange('ml', value)}
          />
          <div className={styles.image}>
            <ImageIcon />
          </div>
          <ControlInput
            type="vertical"
            value={pageSizes.mr}
            handleChange={(value) => onChange('mr', value)}
          />
        </div>
        <ControlInput
          type="horizontal"
          value={pageSizes.mb}
          handleChange={(value) => onChange('mb', value)}
        />
      </div>
    </div>
  );
};

export default ChangePaddingModal;
