import { Dispatch, SetStateAction, useState } from 'react';
import Dropdown from 'UILib/Dropdown/Dropdown';
import DropdownLabel from './DropdownLabel/DropdownLabel';
import CustomArrowsForSizes from './CustomArrows/CustomArrows';
import FontSelector from 'Components/FontSelector/FontSelector';
import ColorSelectionBox from './ColorSelectionBox/ColorSelectionBox';
import SizeStyleControl from 'Components/SizeSelector/SizeStyleControls';
import { ReactComponent as FontIco } from 'Assets/icons/textEditor/font.svg';

import styles from './SettingsModal.module.scss';

interface ISizeStateType {
  value: number;
  step: number;
  max: number;
  min: number;
}

interface IProps {}

const mockOptions = [
  {
    label: <DropdownLabel value={'600px'} />,
    value: 600,
  },
  { label: <DropdownLabel value={'700px'} />, value: 700 },
  { label: <DropdownLabel value={'800px'} />, value: 800 },
];

const SettingsModal = (props: IProps) => {
  const [templateWidth, setTemplateWidth] = useState(600); //TODO: should be changed once we will get real data
  const [currentFontFamily, setCurrentFontFamily] = useState('Inter');

  const [fontSize, setFontSize] = useState({
    value: 14,
    step: 1,
    max: 100,
    min: 2,
  });

  const changeFontWeight = (
    action: 'increment' | 'decrement',
    valueSetter: Dispatch<SetStateAction<ISizeStateType>>
  ) => {
    valueSetter((oldWeight) => {
      let currentValue = oldWeight.value;

      if (
        action === 'increment' &&
        currentValue + oldWeight.step <= oldWeight.max
      ) {
        currentValue += oldWeight.step;
      } else if (
        action === 'decrement' &&
        currentValue - oldWeight.step >= oldWeight.min
      ) {
        currentValue -= oldWeight.step;
      }

      return { ...oldWeight, value: currentValue };
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.settingsSectionContainer}>
        <div className={styles.sectionLabel}>Background:</div>
        <div className={styles.settingsDropdowns}>
          <ColorSelectionBox
            label="Email Background"
            value="#fff"
            onChange={(color) => console.log(color)}
          />
        </div>
        <div className={styles.sectionLabel}>Body:</div>
        <div className={styles.settingsDropdowns}>
          <Dropdown
            type="stroke"
            label={<DropdownLabel value={'600px'} />}
            onChange={(newValue) => setTemplateWidth(newValue)}
            value={templateWidth}
            theme="dark"
            options={mockOptions}
          />
          <ColorSelectionBox
            label="Color"
            value="#fff"
            onChange={(color) => console.log(color)}
          />
        </div>
      </div>
      <div
        className={`${styles.settingsSectionContainer} ${styles.defaultSettings}`}
      >
        <div className={styles.sectionLabel}>Default Text:</div>
        <div className={styles.settingsDropdowns}>
          <div className={styles.settingsRow}>
            <ColorSelectionBox
              label="Text Color"
              value="#fff"
              onChange={(color) => console.log(color)}
            />
            <ColorSelectionBox
              label="Link Color"
              value="#fff"
              onChange={(color) => console.log(color)}
            />
            <FontSelector
              theme="dark"
              select={currentFontFamily}
              fontIcon={<FontIco />}
              headerClassName={styles.fontSelector}
              onChange={(value) => {
                setCurrentFontFamily(value);
              }}
            />
            <SizeStyleControl
              step={fontSize.step}
              max={fontSize.max}
              min={fontSize.min}
              value={`${fontSize.value}`}
              theme="dark"
              customArrows={
                <CustomArrowsForSizes
                  onIncrement={() => changeFontWeight('increment', setFontSize)}
                  onDecrement={() => changeFontWeight('decrement', setFontSize)}
                />
              }
              postfix={<span>px</span>}
              title="Font Size"
              className={styles.sizeFieldWrapper}
              inputClassName={styles.sizeField}
              onChange={(value) => {
                setFontSize((oldWeight) => ({ ...oldWeight, value: +value }));
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsModal;
