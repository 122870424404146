import { useMemo } from 'react';
import { Descendant, Element } from 'slate';
import { TagElement } from 'Editors/types';
import NodeTextEditor from 'Editors/NodeTextEditor/NodeTextEditor';
import { ReactComponent as ListBullets } from 'Assets/icons/ListBullets.svg';
import TemplateNode, { INode, INodeEvent } from './Node';

import styles from './Node.module.scss';

export interface INodeGenerateList extends INode {
  prompt: string;
}

interface IProps {
  node: INodeGenerateList;
  variables?: string[];
  onChange: (node: INode) => void;
  onEvent: (event: INodeEvent) => void;
}

const GenerateList = (props: IProps) => {
  //TODO: this is copy/paste from GenerateText node. need to share this code with this node and all PROMPT based nodes
  const initialPropmtValue: Descendant[] = useMemo(() => {
    const paragraphs = props.node.prompt.split('\n');
    const children: Descendant[] = [];
    for (const paragraph of paragraphs) {
      const words = paragraph.split(' ');
      const paragraphChildren: Descendant[] = [];
      for (const [index, word] of words.entries()) {
        if (word.startsWith('#')) {
          paragraphChildren.push({
            text: '',
          });
          const payload: TagElement = {
            type: 'tag',
            value: word.slice(1),
            children: [{ text: '' }],
          };
          paragraphChildren.push(payload);
          paragraphChildren.push({
            text: index === words.length - 1 ? '' : ' ',
          });
          continue;
        }
        paragraphChildren.push({
          text: word + (index === words.length - 1 ? '' : ' '),
        });
      }
      children.push({
        type: 'paragraph',
        children: paragraphChildren,
      });
    }
    return children;
  }, [props.node.prompt]);

  //TODO: this is copy/paste from GenerateText node. need to share this code with this node and all PROMPT based nodes
  const handleTextChange = (blocks: Descendant[]) => {
    let finalText = '';
    for (const block of blocks as Element[]) {
      if (typeof block === 'string' || block.type !== 'paragraph') continue;

      for (const child of block.children) {
        const element = child as any;

        if (element.type === 'tag') finalText += '#' + element.value;
        else finalText += element.text;
      }
    }

    const newNode = { ...props.node, prompt: finalText };
    props.onChange(newNode);
  };

  return (
    <TemplateNode
      node={props.node}
      onChange={props.onChange}
      onEvent={props.onEvent}
      headerColor="#e09531"
      icon={<ListBullets />}
    >
      <NodeTextEditor
        editorClassName={styles.textEditor}
        tagsList={props.variables ?? []}
        initialValue={initialPropmtValue}
        onChange={handleTextChange}
      />
    </TemplateNode>
  );
};

export default GenerateList;
