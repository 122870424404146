import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { BookleTemplateBlock, IUserProduct } from 'types';
import { RootState } from '../rootReducer';
import { IBookleTemplateEditor } from './booksReducer';

export const UPDATE_SETTINGS_POPUP = 'UPDATE_POPUP';
export const UPDATE_BOOKLE_TEMPLATE_BLOCKS = 'UPDATE_BOOKLE_TEMPLATE_BLOCKS';
export const UPDATE_BOOKLE_TEMPLATE_HOVERED_BLOCK =
  'UPDATE_BOOKLE_TEMPLATE_HOVERED_BLOCK';
export const UPDATE_BOOKLE_TEMPLATE_TEXT_EDITOR =
  'UPDATE_BOOKLE_TEMPLATE_TEXT_EDITOR';

type DispatchType = ThunkDispatch<RootState, void, Action>;

export const bookSettingsPopupAction = (payload: {
  open?: boolean;
  initialState?: IUserProduct;
}) => async (dispatch: DispatchType) => {
  const stateAction = {
    type: UPDATE_SETTINGS_POPUP,
    payload,
  };
  dispatch(stateAction);
};

export const updateBookleTemplateBlocks = (
  payload: BookleTemplateBlock[]
) => async (dispatch: DispatchType) => {
  const stateAction = {
    type: UPDATE_BOOKLE_TEMPLATE_BLOCKS,
    payload,
  };
  dispatch(stateAction);
};

export const updateBookleTemplateHoveredBlock = (
  payload: BookleTemplateBlock | undefined
) => async (dispatch: DispatchType) => {
  const stateAction = {
    type: UPDATE_BOOKLE_TEMPLATE_HOVERED_BLOCK,
    payload,
  };
  dispatch(stateAction);
};

export const updateBookleTemplateTextEditor = (
  payload: IBookleTemplateEditor
) => async (dispatch: DispatchType) => {
  const stateAction = {
    type: UPDATE_BOOKLE_TEMPLATE_TEXT_EDITOR,
    payload,
  };
  dispatch(stateAction);
};
