export const PUSH_DATA_TO_GENERATION_TASK_MUTATION = {
  operationName: 'pushUserDataToTask',
  query: `mutation pushUserDataToTask(
            $taskId: String!
            $data: String!
            $path: String!
          ) {
            pushUserDataToTask(
              taskId: $taskId
              data: $data
              path: $path
            ) {
                message           
            }
          }`,
};
