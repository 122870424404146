import { Descendant, Element } from 'slate';
import TemplateNode, { INode, INodeEvent } from './Node';
import { ReactComponent as ImageIcon } from 'Assets/icons/rounded-image.svg';
import NodeTextEditor from 'Editors/NodeTextEditor/NodeTextEditor';
import styles from './Node.module.scss';

const __tmp__initialValue: Descendant[] = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
];

export interface INodeGenerateImage extends INode {
  prompt: string;
}
interface IProps {
  node: INodeGenerateImage;
  variables?: string[];
  onChange: (node: INode) => void;
  onEvent: (event: INodeEvent) => void;
}
const GenerateImage = (props: IProps) => {
  const handleTextChange = (blocks: Descendant[]) => {
    let finalText = '';
    for (const block of blocks as Element[]) {
      if (typeof block === 'string' || block.type !== 'paragraph') continue;

      for (const child of block.children) {
        const element = child as any;

        if (element.type === 'tag') finalText += '#' + element.value;
        else finalText += element.text;
      }
    }

    const newNode = { ...props.node, prompt: finalText };
    props.onChange(newNode);
  };

  return (
    <TemplateNode
      node={props.node}
      onChange={props.onChange}
      onEvent={props.onEvent}
      headerColor="#25BB73"
      icon={<ImageIcon />}
    >
      <NodeTextEditor
        editorClassName={styles.textEditor}
        tagsList={props.variables ?? []}
        initialValue={__tmp__initialValue}
        onChange={handleTextChange}
      />
    </TemplateNode>
  );
};

export default GenerateImage;
