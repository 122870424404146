import { useRef } from 'react';
import { connect } from 'react-redux';
import { useDrop } from 'react-dnd';
import { RootState } from 'store/rootReducer';
import { updateBookleTemplateBlocks } from 'store/books/booksActions';
import { addItemToContainer } from '../utils';
import { BookleTemplateBlock } from 'types';
import DropPlace from '../DropPlace/DropPlace';
import DraggableContent from '../DraggableContent/DraggableContent';

import styles from './Content.module.scss';

interface IProps {
  templateBlocks: BookleTemplateBlock[];
  updateBlocks: (payload: BookleTemplateBlock[]) => void;
  hoveredItem: BookleTemplateBlock | undefined;
}

const Content = (props: IProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: 'MENU_ITEM',
      drop: (item: BookleTemplateBlock, monitor) => {
        if (monitor.isOver({ shallow: true })) {
          const data = addItemToContainer(item, props.templateBlocks);
          props.updateBlocks(data);
        }
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [props.templateBlocks]
  );

  const combinedRef = (node: any) => {
    containerRef.current = node;
    drop(node);
  };

  return (
    <div className={styles.container} ref={combinedRef}>
      {isOver && !props.templateBlocks?.length && (
        <div className={styles.dropItem}>
          <div className={styles.text}>Drop Your Block Here</div>
        </div>
      )}
      {props.templateBlocks?.map((item: BookleTemplateBlock) => (
        <DraggableContent key={item?.id} item={item} />
      ))}
      {isOver && !!props.templateBlocks?.length && !props.hoveredItem && (
        <DropPlace />
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  templateBlocks: state.books.bookleTemplateBlocks,
  hoveredItem: state.books.bookleTemplateHoveredBlock,
});

const mapDispatchToProps = {
  updateBlocks: (payload: BookleTemplateBlock[]) =>
    updateBookleTemplateBlocks(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(Content);
