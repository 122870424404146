export const CREATE_GENERATION_TEMPLATE_MUTATION = {
  operationName: 'createGenerationTemplate',
  query: `mutation createGenerationTemplate(
          $actions: String!
        ) {
          createGenerationTemplate(
            actions: $actions
          ) {
            _id
            createdAt
            updatedAt
          }
        }`,
};
