import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { graphQlCall } from 'graphql/utils';
import { RootState } from 'store/rootReducer';
import { updateBookleTemplateTextEditor } from 'store/books/booksActions';
import { IBookleTemplateEditor } from 'store/books/booksReducer';
import { useParams } from 'react-router-dom';
import { UseOnClickOutside } from 'utils/UseOnClickOutside';
import { IGenerationBlock } from './GenerationPreview/GenerationBlock/GenerationBlock';
import { INodeUserInput } from './Nodes/UserInput';
import { INode } from './Nodes/Node';
import Button from 'UILib/Button/Button';
import Loader from 'UILib/Loader/Loader';
import queries from 'graphql/queries';
import TemplateView from './TemplateView/TemplateView';
import GenerationPreview from './GenerationPreview/GenerationPreview';
import EditHeader from 'Components/Common/EditHeader/EditHeader';
import TextEditorToolbar from 'Components/TextEditorToolbar/TextEditorToolbar';

import styles from './PageBookleTemplateEditor.module.scss';

interface IProps {
  templateTextEditor: IBookleTemplateEditor;
  updateTextEditor: (payload: IBookleTemplateEditor) => void;
}

const PageBookleTemplateEditor = ({
  templateTextEditor,
  updateTextEditor,
}: IProps) => {
  const { templateId } = useParams<{ templateId: string }>();
  const [generationTemplateData, setGenerationTemplateData] = useState<any>({});
  const [previewBlocks, setPreviewBlocks] = useState<IGenerationBlock[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [_tmp_generationData, _tmp_setGenerationData] = useState<any>({});
  const [_tmp_generationTaskId, _tmp_setGenerationTaskId] = useState();
  const [_tmp_nodes, _tmp_setNodes] = useState<any>({});

  const ref = useRef<HTMLDivElement>(null);
  UseOnClickOutside(ref, (e) => {
    const textEditorElements = document.querySelectorAll(
      '[id^="text_editor_"]'
    );

    const clickedInsideTextEditor = Array.from(
      textEditorElements
    ).some((element) => element.contains(e.target as any));

    if (
      ref.current &&
      !ref.current.contains(e.target as any) &&
      !clickedInsideTextEditor
    ) {
      updateTextEditor({ editor: undefined, selection: undefined });
    }
  });

  const handleRedirectToTemplates = () => {};

  const handleTemplateSave = () => {
    console.log('SAVING....');

    //brining structure back to tree

    console.log('SAVE is complete');
  };

  useEffect(() => {
    setLoading(true);
    graphQlCall({
      queryTemplateObject: queries.GET_ONE_GENERATION_TEMPLATE,
      values: { id: templateId },
    })
      .then((data) => {
        setGenerationTemplateData(data);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  const handleNodeExposure = (node: INode) => {
    const block = {
      node: node,
    } as IGenerationBlock;
    setPreviewBlocks([...previewBlocks, block]);
  };

  const handleNodeVariableChange = (node: INode) => {
    const variableCurrentValue = node.variable;
    previewBlocks.forEach((block) => {
      if (block.node.id === node.id) {
        block.node.variable = variableCurrentValue;
      }
    });

    setPreviewBlocks([...previewBlocks]);
  };

  const handleDataChange = (nodes: any) => {
    //TODO: figure out another way of doing this dictionary processing
    let data: any = {};
    for (const key in nodes) {
      const node = nodes[key];
      if (node.type === 'UserInput') {
        const nodeUi = node as INodeUserInput;
        //capture variables
        for (const form of nodeUi.forms) {
          for (const input of form.fields) {
            data[input.id] = input.example;
          }
        }
      }
    }

    _tmp_setNodes(nodes);
    _tmp_setGenerationData(data);
  };

  const handleGenerationStart = async () => {
    console.log('GENERATION STARTED...');

    const task = await graphQlCall({
      queryTemplateObject: queries.CREATE_GENERATION_TASK_MUTATION,
      values: { templateId: templateId },
      headerType: 'USER-AUTH',
    });

    console.log('TASK:', task);

    const response = await graphQlCall({
      queryTemplateObject: queries.PUSH_DATA_TO_GENERATION_TASK_MUTATION,
      values: {
        taskId: task.task._id,
        data: JSON.stringify(_tmp_generationData),
        path: '',
      },
      headerType: 'USER-AUTH',
    });
    console.log('push data response: ', response);
  };

  if (loading) {
    return (
      <div className={styles.loaderContainer}>
        <Loader color="#d0d0d0" />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div ref={ref} id="header">
        <EditHeader
          showConfirmButton={true}
          pageName={generationTemplateData?.name || ''}
          title="Template name"
          handleConfirm={handleTemplateSave}
          handleGoBack={handleRedirectToTemplates}
          buttonPlaceholder="Save"
          additionalButtons={
            <Button
              appearance="stroke"
              width={160}
              height={40}
              onClick={handleGenerationStart}
            >
              Preview
            </Button>
          }
          customHeaderPlace="right"
          showCustomHeader={!!templateTextEditor.editor}
          className={styles.header}
          customHeader={
            <TextEditorToolbar
              editor={templateTextEditor.editor}
              selection={templateTextEditor.selection}
              showListItems={false}
            />
          }
        />
      </div>
      <div className={styles.workbench}>
        <div className={styles.nodeCanvas}>
          <TemplateView
            generationTemplateData={generationTemplateData}
            onVariableChange={handleNodeVariableChange}
            onExpose={handleNodeExposure}
            onChange={handleDataChange}
          />
        </div>
        <div className={styles.generationPreview}>
          <GenerationPreview generationBlocks={previewBlocks} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  templateTextEditor: state.books.bookleTemplateTextEditor,
});

const mapDispatchToProps = {
  updateTextEditor: (payload: IBookleTemplateEditor) =>
    updateBookleTemplateTextEditor(payload),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PageBookleTemplateEditor);
